import '../../css/ContactForm.css';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import greenCheckMark from "../../svg/green_checkmark.png";
import error from "../../svg/close.png";



const ContactForm = () => {
    const { t } = useTranslation("global");
    const form = useRef();
    const [emailSent, setEmailSent] = useState(null);
    const [loading, setLoading] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        setEmailSent(null);

        emailjs.sendForm(
            "service_w3ayt6n",
            "template_gg4julp",
            form.current,
            "VbAKaioqfBm6WBIdy"
        ).then(
            (result) => {
                setEmailSent(true);
                setLoading(false);
            },
            (error) => {
                setEmailSent(false);
                setLoading(false);
            }
        );
        e.target.reset();
    };

    const openContact = () =>{
        setEmailSent(null);
    };
    return (
        <section id="contact-form" className="contact-form">
            {loading ? (
                <p className="loading-text">{t("contacts.sending")}</p>
            ) : emailSent === true ? (
                <div>
                    <p className="success-text">
                        <img alt="Green checkmark" src={greenCheckMark}  style={{ width: '30px', height: 'auto', marginRight: '8px' }}/>
                        {t("contacts.success")}.
                    </p>
                    <div className="send-message-button">
                        <button type="button" onClick={openContact}>
                            <span className='send-message-span'><b>{t("contacts.try_again")}</b></span>
                        </button>
                    </div>
                </div>
                ) : emailSent === false ? (
                <p className="error-text">
                    <img alt="error" src={error}  style={{ width: '30px', height: 'auto', marginRight: '8px' }}/>
                    {t("contacts.error")}.
                </p>            ) : (
                <form className="contact-form" onSubmit={sendEmail} ref={form}>
                    <input id="contact-input" name="from_name" className="contact-form" type="text" placeholder={t("contacts.name_placeholder")} required/>
                    <input id="contact-input" name="email" className="contact-form" type="email" placeholder={t("contacts.email_placeholder")} required/>
                    <textarea id="contact-input" name="message" className="contact-form" placeholder={t("contacts.message_placeholder")} required/>
                    <div className="send-message-button">
                        <button type="submit" disabled={loading}>
                            <span className='send-message-span'><b>{t("contacts.send_message")}</b></span>
                        </button>
                    </div>
                </form>
            )}
        </section>
    );
};

export default ContactForm;
