import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import background from "../svg/teacher-background.png";
import axios from "axios";
import Loader from "./components/Loader";
import "../css/Games.css";

const Modal = ({ item, onClose }) => {
    const [t, i18n] = useTranslation("global");

    if (!item) return null;

    return (
        <div className="games-modal-overlay" onClick={onClose}>
            <div className="games-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="games-modal-close" onClick={onClose}>✖</button>
                <h2 className="games-modal-title">{item.name}</h2>

                <div className="games-modal-details">
                    <p><strong>{t("games.description")}:</strong> {item.description}</p>
                    <p><strong>{t("games.country")}:</strong> {item.country.name}</p>
                    <p><strong>{t("games.language")}:</strong> {item.language.name}</p>
                    <p><strong>{t("games.stem_area")}:</strong> {item.stem_area.name}</p>
                </div>

                <div className="games-modal-footer">
                    <a href={item.link} target="_blank" rel="noopener noreferrer" className="games-modal-link">
                        {t("games.play_game")}
                    </a>
                </div>
            </div>
        </div>
    );
};

const Games = () => {
    const [t, i18n] = useTranslation("global");
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState(null);
    const [games, setGames] = useState(null);
    const [searchText, setSearchText] = useState("");
    const inputRef = useRef(null);

    // Filter states
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");

    // Modal state
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/games/' + i18n.language)
            .then(response => {
                setItems(response.data);
                setGames(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data from API', error);
            });
    }, [i18n.language]);

    function searchBarChange(event) {
        setSearchText(event.target.value);
        filterGames(event.target.value, selectedCountry, selectedCategory);
    }

    function handleFilterChange(filterType, value) {
        if (filterType === "country") setSelectedCountry(value);
        if (filterType === "category") setSelectedCategory(value);

        filterGames(searchText,filterType === "country" ? value : selectedCountry,
            filterType === "category" ? value : selectedCategory);
    }

    function filterGames(text, country, category) {
        if (!games) return;

        let filteredGames = games.filter(game =>
            (!text || game.name.toLowerCase().includes(text.toLowerCase()) ||
                game.link.toLowerCase().includes(text.toLowerCase()) ||
                game.description.toLowerCase().includes(text.toLowerCase()) ||
                game.country.name.toLowerCase().includes(text.toLowerCase()) ||
                game.stem_area.name.toLowerCase().includes(text.toLowerCase()) ||
                game.language.name.toLowerCase().includes(text.toLowerCase())) &&
            (!country || game.country.name === country) &&
            (!category || game.stem_area.name === category)
        );
        setItems(filteredGames);
    }

    // Extract unique filter values
    const countries = [...new Set(games?.map(game => game.country.name) || [])];
    const categories = [...new Set(games?.map(game => game.stem_area.name) || [])];

    return (
        <div className="App mx-auto">
            {isLoading ? (
                <div style={{ width: '100%', height: "100%", position: "relative" }}>
                    <Loader />
                </div>
            ) : (
                <div
                    style={{
                        height: "100vh",
                        backgroundImage: `url(${background})`,
                        backgroundSize: "cover",
                        backgroundPosition: "left",
                        backgroundRepeat: "repeat",
                        width: "100%",
                        position: "fixed",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div className="games-content-title">
                        <h1>{t("games.title")}</h1>
                    </div>

                    {/* Filter Section */}
                    <div className="filter-container">
                        <input
                            ref={inputRef}
                            className="stem-input"
                            type="text"
                            placeholder={t("slider.search_placeholder")}
                            onChange={searchBarChange}
                            style={{ height: "7vh", width: "80%", fontSize: "2vh" }}
                        />

                        <div className="filter-dropdowns">
                            <select className="stem-input" onChange={(e) => handleFilterChange("country", e.target.value)} value={selectedCountry}>
                                <option value="">{t("games.select_country")}</option>
                                {countries.map(country => (
                                    <option key={country} value={country}>{country}</option>
                                ))}
                            </select>

                            <select className="stem-input" onChange={(e) => handleFilterChange("category", e.target.value)} value={selectedCategory}>
                                <option value="">{t("games.select_category")}</option>
                                {categories.map(category => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Game List */}
                    <div className="games-content">
                        <div className="games-content-grid">
                            {items && items.length > 0 ? (
                                items.map((item) => (
                                    <div
                                        key={item.id}
                                        className="game-item bg-white p-4 rounded-md shadow-sm border hover:bg-gray-100 cursor-pointer transition"
                                        onClick={() => setSelectedItem(item)}
                                    >
                                        <h5 className="text-gray-500 text-sm">{item.stem_area.name}</h5>
                                        <h3 className="text-lg font-semibold">{item.name}</h3>
                                    </div>
                                ))
                            ) : null}
                        </div>
                        {items && items.length == 0 ? (
                            <div className="no-items-container">
                                <h3>{t('games.no_games')}</h3>
                            </div>
                        ):null}
                    </div>

                </div>
            )}

            {/* Modal */}
            <Modal item={selectedItem} onClose={() => setSelectedItem(null)} />
        </div>
    );
};

export default Games;
